
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Segoe UI', 'RobotoRegular', 'Droid Sans', 'Helvetica Neue', Tahoma, Helvetica, sans-serif;
  font-size: 14px;
  background: #ffffff;
  color: #333;
}

* {
  box-sizing: border-box;
}

h1 {
  margin-top: 0;
  font-size: 1.5em;
  text-transform: uppercase;
}

h2 {
  margin-top: 0;
  font-size: 1.5em;
  text-transform: uppercase;
}

h3 {
  font-size: 1.1em;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}


@media only screen and (max-width: 799px) {
  .header {
    min-height: 50px;
    border-bottom: 3px solid #253d7d;
    background: #ffffff;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(50,50,50,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(50,50,50,0.75);
    box-shadow: 0px 3px 5px 0px rgba(50,50,50,0.75);
  }

  .header__logo {
    margin-top: 10px;
    margin-left: 10px;
    height: 30px;
  }

  .header__menuTrigger {
    float: right;
    height: 30px;
    margin-top: 8px;
    margin-right: 10px;
  }

  .header__searchTrigger {
    float: right;
    height: 30px;
    margin-top: 8px;
    margin-right: 10px;
    display: none;
  }

  .header-menu {
    display: none;
  }

  .searchFormContainer {
    padding-right: 12px;
    padding-left: 12px;
    overflow: hidden;
    height: 0px;
    transition: height 0.2s;
    display: none;
  }

  .searchFormContainer.show {
    height: 45px;
  }

  .searchFormContainer input {
    border: 1px solid #555555;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
  }

  .searchFormContainer input:focus {
    outline-width: 0;
  }

  .container {
    padding: 50px 0 10px 0;
  }

  .breadcrumbs {
    font-size: 0.8em;
    padding: 10px;
  }

  .breadcrumbs a {
    text-decoration: none;
    color: #40588b;
    font-weight: bold;
  }

    .iframeGoogleMaps {
      max-width: 500px;
      max-height: 400px;
      width: 100%;
      height: 400px;
      margin: 0 auto;
      display: block;
      border: 0px;
    }
  
    .bannerImage {
      margin: 0;
      padding: 0;
      height: 150px;
      width: 100%;
      display: block;
      background-position: center center;
      background-size: cover;
    }

    .bannerImage.orientationBottom {
      background-position: bottom center;
    }

    .block--gray {
      padding: 50px 15px;
      background: #003f72;
      color: #ffffff;
      position: relative;
    }
  
    .block--puffs {
      padding: 15px;
      background: #ffffff;
      position: relative;
      display: flex;
      flex-flow: row wrap;
    }
  
    .block {
      padding: 50px 15px;
      background: #ffffff;
      position: relative;
    }

    .blockContent {
      max-width: 500px;
      margin: 0 auto;
    }

    .blockContent.flex {
      display: flex;
      flex-flow: row wrap;
      position: relative;
    }

    .blockContent.flexCenter {
      display: flex;
      flex-flow: row wrap;
      position: relative;
      justify-content: center;
    }
  
    .block h2, .block--gray h2, .block h1, .block--gray h1 {
      text-align: center;
    }
  
    .blockIcon {
      display: block;
      position: absolute;
      left: 15px;
      top: 18px;
      margin: 0;
      font-size: 20px;
      font-family: FontAwesome;
      color: #ffffff;
    }
  
    .subCat { 
      position: relative;
      margin-bottom: 30px;
      height: 120px;
      flex-basis: 120px;
      flex-shrink: 2;
      padding: 10px;
    }
  
    .subCat__img {
      height: 100%;
      width: 100%;
      border: 1px solid #333;
      background-position: center center;
      border-radius: 15px;
      background-size: cover;
      margin-right: 10px;
    }
  
    .subCat__info {
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      color: #000000;
    }
  
    .block .prodNav, .block--gray .prodNav {
      height: 140px;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #333;
      position: relative;
      background: #aaaaaa;
      background-position: right;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }
  
      .prodNav .bannerText {
        text-transform: uppercase;
        width: 100%;
        padding: 7px;
        position: absolute;
        bottom: 15px;
        text-align: center;
        background: RGBA(37,61,125,0.8);
        color: #ffffff;
        
      }

  .productContent {
    width: 100%;
    max-width: 450px;
    margin-bottom: 0px;
    padding-bottom: 30px;
  }

  .productContent img {
    border: 1px solid #333333;
  }

  .Products h1 {
    border-bottom: 1px solid #333333;
    display: block;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .header {
    height: 120px;
    border-bottom: 1px solid #000000;
    background: #ffffff;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(50,50,50,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(39, 22, 22, 0.75);
    box-shadow: 0px 3px 5px 0px rgba(50,50,50,0.75);
  }

  .header__logo {
    margin-top: 20px;
    margin-left: 20px;
    height: 50px;
  }

  .header__menuTrigger, .header__searchTrigger {
    display: none;
  }

  .header-menu {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 33px;
    padding-left: 5px;
    /* background: #003f72; */
    width: 100%;
    /* border-top: 3px solid #003f72; */
    border-bottom: 3px solid #253d7d;
  }

  .header-menu a {
    float: left;
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    /* border-right: 1px solid #000000; */
    line-height: 30px;
    /* background: #003f72; */
    color: #000000;
    box-sizing: border-box;
    text-decoration: none;
    /* text-transform: uppercase; */
    /* transition: background-color 0.3s, color 0.3s; */
    border-radius: 6px 6px 0px 0px;
  }
  .header-menu a:hover {
    background: #e9eef3;
  }
  
  .header-menu a.selected {
    background: #253d7d;
    color: #ffffff;
  }

  .searchFormContainer {
    display: none;
  }

  .container {
    padding: 120px 0 10px 0;
  }

  .breadcrumbs {
    font-size: 0.8em;
    padding-left: 20px;
    padding-top: 10px;
  }

  .breadcrumbs a {
    text-decoration: none;
    color: #40588b;
    font-weight: bold;
  }

  .breadcrumbs a:hover {
    text-decoration: underline;
  }

    .iframeGoogleMaps {
      width: 100%;
      height: 600px;
      margin: 0 auto;
      display: block;
      border: 0px;
    }
  
    .bannerImage {
      margin: 0;
      padding: 0;
      height: 300px;
      width: 100%;
      display: block;
      background-position: center center;
      background-size: cover;
      position: relative;
    }

    .bannerImage.orientationBottom {
      background-position: bottom center;
    }

    .blockContent {
      max-width: 1024px;
      margin: 0 auto;
    }

    .blockContent.flex {
      display: flex;
      flex-flow: row wrap;
      position: relative;
    }
    
    .blockContent.flexCenter {
      display: flex;
      flex-flow: row wrap;
      position: relative;
      justify-content: center;
    }

    .block--gray {
      padding: 50px 15px;
      background: #003f72;
      color: #ffffff;
      position: relative;
    }
  
    .block--puffs {
      padding: 15px;
      background: #ffffff;
      position: relative;
      display: flex;
      flex-flow: row wrap;
    }
  
    .block {
      padding: 50px 15px;
      background: #ffffff;
      position: relative;
    }
  
    .block h2, .block--gray h2, .block h1, .block--gray h1 {
      text-align: center;
    }
  
    .blockIcon {
      display: none;
    }
  
    .subCat { 
      position: relative;
      margin-bottom: 30px;
      height: 160px;
      flex-basis: 200px;
      flex-shrink: 2;
      padding: 10px;
    }
  
    .subCat__img {
      height: 100%;
      width: 100%;
      border: 1px solid #333;
      background-position: center center;
      border-radius: 15px;
      background-size: cover;
      margin-right: 10px;
    }
    
  
    .subCat__info {
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      color: #000000;
    }
  
    .block .prodNav, .block--gray .prodNav {
      flex-basis: 48%;
      flex-shrink: 2;
      height: 140px;
      /* max-width: 300px;
      width: 300px;
      margin-right: 10px;
      margin-bottom: 10px; */
      margin: 1%;
      border: 1px solid #333;
      position: relative;
      background: #aaaaaa;
      background-position: right;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      transition: transform .5s;
    }

    .block .prodNav:hover, .block--gray .prodNav:hover {
      -webkit-box-shadow: 0px 0px 5px 3px rgba(37, 61, 125, 0.75);
      -moz-box-shadow: 0px 0px 5px 3px rgba(37, 61, 125, 0.75);
      box-shadow: 0px 0px 5px 3px rgba(37, 61, 125, 0.75);
      transform: scale(1.03);
    }

    .block .prodNav:hover .bannerText, .block--gray .prodNav:hover .bannerText {
      background: RGBA(37,61,125,0.95);
    }
  
      .prodNav .bannerText {
        text-transform: uppercase;
        width: 100%;
        padding: 7px;
        position: absolute;
        bottom: 15px;
        text-align: center;
        background: RGBA(37,61,125,0.8);
        color: #ffffff;
        
      }
  .productContent {
    float: left;
    margin-right: 10px;
    max-width: 450px;
    /* width: 100%; */
    margin-bottom: 0px;
    padding-bottom: 30px;
  }

  .productContent img {
    border: 1px solid #333333;
  }
}




    /* ActionButtons */
    button.readMore {
      margin-top: 5px;
      color: rgb(255, 255, 255);
      background: #cc8787;
      border-radius: 0px;
      padding: .6em 1.0em;
      padding-right: 3.8em;
      cursor: pointer;

      display: inline-block;
      font-family: "Montserrat", "Trebuchet MS", Helvetica, sans-serif;
      -webkit-font-smoothing: antialiased;
      position: relative;
    }

    button.readMore:after {
        font-family: 'FontAwesome', sans-serif;
        content: "\F054";
        width: 2.0em;
        font-size: 1.2em;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding-top: inherit;
        padding-bottom: inherit;
        background: rgb(183, 94, 94);
    }
    button.readMore:before {
      background: rgba(0, 0, 0, 0.1);
    }




    /* FORMULÄR */
    input, textarea {
      width: 100%;
      border: 1px solid #fff;
      background: #ffffff;
      border-radius: 6px;
      padding: 8px;
      margin-top: 15px;
    }

    textarea {
      resize: none;
      height: 70px;
    }

    button {
      padding: 8px;
      border: 1px solid #fff;
      border-radius: 6px;
      background: #ffffff;
      float: right;
      margin-left: 10px;
      margin-top: 10px;
      font-size: 1.1em;
    }






    /* *************************** BURGER MENU ******************************** */

    /* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #3f3f3f;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;

  background: url(/src/resources/images/stock8.jpg) #ffffff;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 60px 10px;
    font-size: 1.15em;
    opacity: 0.95;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 0em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

a.menu-item {
  color: #8a8a8a;
  text-decoration: none;
  outline: none;
  font-size: 0.95em;
  line-height: 2em;
}

a.menu-item::before {
  margin-right: 10px;
  color: #555555;
}

a.menu-item.one::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f015";
}

a.menu-item.two::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0c1";
}

a.menu-item.three::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f64f";
}

a.menu-item.four::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0c0";
}

a.menu-item.five::before {
  font-family: "Font Awesome 5 Free"; font-weight: 300; content: "\f086";
}


/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}



/* *********** MODAL ***************** */

.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}